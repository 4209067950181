<script>
import { render } from 'micromustache'
import CourseCard from '@/components/CourseCard.vue'
import BaseLoader from '@/components/BaseLoader.vue'

import {
    BaseButton,
    BaseMultiSelect,
    BaseZipcode,
    BaseCheckbox,
    IconCross,
    IconArrowheadLeft,
    IconArrowheadRight,
} from 'aca-design'


function debounce (fn, delay) {
    var timeoutID = null
    return function () {
        clearTimeout(timeoutID)
        var args = arguments
        var that = this
        timeoutID = setTimeout(function () {
            fn.apply(that, args)
        }, delay)
    }
}

export default {

    name: 'HomeScreen',

    components: {
        BaseLoader,
        BaseButton,
        BaseMultiSelect,
        BaseZipcode,
        BaseCheckbox,
        IconCross,
        IconArrowheadLeft,
        IconArrowheadRight,
        CourseCard,
    },

    props: {
        model: {
            type: Object,
            default() {
                return {}
            }
        },
        coursesLoading: {
            type: Boolean,
        },
        isStage: {
            type: Boolean,
            default: true
        },
        displayCoursesList: {
            type: Boolean,
            default: true
        },
        matchingCourses: {
            type: Array,
        },
        matchingCoursesLength: {
            type: Number,
        },
        filteredLevels: {
            type: Array,
        },
        levelFilter: {
            type: Object,
        },
        planningFilter: {
            type: String,
        },
        filteredSubjects: {
            type: Array,
        },
        subjectsFilter: {
            type: Array,
        },
        zipCodeFilter: {
            type: [String, Object],
        },
        allPeriods: {
            type: Array,
        },
        filteredPeriods: {
            type: Array,
        },
        periodFilter: {
            type: Object,
        },
        locationFilter: {
            type: Array,
        },
        selectedCourses: {
            type: Array,
        },
        getDetailById: {
            type: Function,
        },
        readableLevel: {
            type: Function,
        },
        landing: {
            type: Object,
            required: true
        },
    },

    data () {
        return {
            observer: null,
            courseElementInViewport: null,
            checkedVariantsIds: {},
            virtualScrollProgress: 0,
            scrollLeft: 0,
        }
    },

    computed: {
        activeScrollIndicator() {
            if (this.virtualScrollProgress >= 0 && this.virtualScrollProgress < 0.25) {
                return 0
            } else if (this.virtualScrollProgress >= 0.25 && this.virtualScrollProgress < 0.50) {
                return 1
            } else if (this.virtualScrollProgress >= 0.50 && this.virtualScrollProgress < 0.75) {
                return 2
            } else {
                return 3
            }
        },

        landingLocationFilter() {
            return this.landing.filters.find(({ type }) => type === 'location')
        }
    },

    watch: {
        matchingCourses: {
            handler: function () {
                this.matchingCourses.forEach((group) => {
                    if (!this.checkedVariantsIds[group[0].id]) {
                        this.$set(this.checkedVariantsIds, group[0].id, group[0].id)
                    }
                })
            },

            immediate: true
        },


    },

    mounted() {
        this.selectedCourses.forEach(({course, group}) => {
            this.$set(this.checkedVariantsIds, group[0].id, course.id)
        })
    },

    created() {
        this.debouncedHandleScroll = debounce(this.handleScroll, 200)
    },

    methods: {
        renderMustache(str, context) {
            return render(str, context)
        },

        handleScroll() {
            const el = this.$el.querySelector('#observer-root')

            const percentageScrollProgress = el.scrollLeft / el.scrollWidth
            const percentageLoadProgress = this.matchingCourses.length / this.matchingCoursesLength
            this.virtualScrollProgress = percentageScrollProgress * percentageLoadProgress

            if (el.scrollWidth - el.clientWidth - el.scrollLeft < 500) {
                this.$emit('loadMore')
            }

            this.scrollLeft = el.scrollLeft
        },

        handlePrevClick() {
            const parent = this.$el.querySelector('#observer-root')
            const scrollLeft = parent.scrollLeft
            const courseElementWidth = this.$el.querySelector('.one-course')?.clientWidth

            if (scrollLeft) {
                parent.scrollBy({
                    top: 0,
                    left: -courseElementWidth,
                    behavior: 'smooth'
                })
            }
            // else {
            //     const allCourses = this.$el.querySelectorAll('#observer-root .one-course')
            //     const last = allCourses[allCourses.length - 1]
            //     last.scrollIntoView({ behavior: 'smooth', inline:'center' })
            // }
        },

        handleNextClick() {
            const parent = this.$el.querySelector('#observer-root')
            const scrollLeft = parent.scrollLeft
            const scrollWidth = parent.scrollWidth
            const clientWidth = parent.clientWidth
            const courseElementWidth = this.$el.querySelector('.one-course')?.clientWidth

            if (scrollLeft + clientWidth < scrollWidth) {
                parent.scrollBy({
                    top: 0,
                    left: courseElementWidth,
                    behavior: 'smooth'
                })
            }
            // else {
            //     const allCourses = this.$el.querySelectorAll('#observer-root .one-course')
            //     const first = allCourses[0]
            //     first.scrollIntoView({ behavior: 'smooth', inline:'center' })
            // }
        },

        handleVariantChange(course, group) {
            this.$set(this.checkedVariantsIds, group[0].id, course.id)

            const isAlreadySelected = this.selectedCourses.find((selectedCourse) => selectedCourse.group[0].id === group[0].id)
            if (isAlreadySelected) {
                this.$emit('selectedVariantChange', course, group)
            }
        },

        handleSelectCourseClick(group, courseId) {
            const course = group.find((c) => c.id === courseId)
            this.$emit('selectCourseClick', group, course)
        },

        handleRemoveCourseClick(group) {
            this.$emit('removeCourseClick', group[0].id)
        },
        readablePeriodFilter(periodFilter) {
            const readable  = {
                "Janvier (hors vacances)": ` en <span class="stabilo">Janvier</span>`,
                "Mars (hors vacances)": ' en <span class="stabilo">Mars</span>',
                "Avril (hors vacances)": ' en <span class="stabilo">Avril</span>',
                "Vacances de printemps": ' au <span class="stabilo">Printemps</span>',
                "Juin (hors vacances)": ` en <span class="stabilo">Juin</span>`,
                "Pré-rentrée": ' à la <span class="stabilo">Pré-rentrée ou en été</span>',
                "Septembre (hors vacances": ` en <span class="stabilo">Septembre</span>`,
                "Vacances de la Toussaint": ` à la <span class="stabilo">Toussaint</span>`,
                "Octobre (hors vacances)": ` en <span class="stabilo">Octobre</span>`,
                "Novembre (hors vacances)": ` en <span class="stabilo">Novembre</span>`,
                "Décembre (hors vacances)": ` en <span class="stabilo">Décembre</span>`,
                "Vacances de Noël": ` à <span class="stabilo">Noël</span>`,
                "Vacances d'hiver": ` en <span class="stabilo">Hiver</span>`,
            }

            return readable[periodFilter.value]
        }

    },

}
</script>

<template>
    <div class="home-screen mt-3 lg:mt-6 lg:mt-0">
        <div class="w-full flex justify-center lg:hidden mb-5" v-if="!displayCoursesList && landing && landing.manuscrit" >
            <div class="block-type-wysiwyg block-manuscrit" v-html="renderMustache(landing.manuscrit, { model: model, count: matchingCoursesLength })"></div>
            <img style="width:37px; height: 45px;transform: scaleX(-1);  position: relative; top: 5px" src="../assets/arrow-manuscrit.svg"/>
        </div>

        <div class="block lg:flex pb-3 mb-4">
            <div class=" lg:basis-full" v-if="landing.filters">
                <div v-if="landingLocationFilter" class="mb-4 flex flex-wrap ">
                    <div v-for="key in Object.keys(landingLocationFilter.values)"
                      :key="key"
                      class="px-5 min-h-5xl py-2 items-center mb-2 rounded-md inline-flex space-x-3 basis-full lg:basis-auto lg:mr-3 flex-wrap"
                      :class="{ 'bg-coral' : key === 'c', 'bg-yellow' : key === 'l' }"
                      @click="$emit('locationFilterChange', key, landingLocationFilter.values, $event)">
                        <BaseCheckbox @click.prevent.stop
                          :checked="locationFilter.includes(key)"/>
                        <span class="font-bold">{{ landingLocationFilter.values[key] }}</span>
                        <BaseZipcode v-if="key === 'c' " class="zipcode" style="flex-grow:1; max-width:360px;"
                          @click.prevent.stop="$emit('locationFilterAdd', key, landingLocationFilter.values, $event)"
                          placeholder="Ville ou code postal"
                          :value="zipCodeFilter"
                          @valueChange="$emit('zipCodeFilterChange', $event)"> </BaseZipcode>
                    </div>
                </div>
                <div class="lg:flex">
                    <template v-for="filter in landing.filters" >
                        <template v-if="filter.type === 'level'">
                            <div :class="filter.classname" :key="filter.name">
                                <BaseMultiSelect :key="JSON.stringify(filteredLevels)"
                                  :options="[...filteredLevels].sort((a, b) => b.value - a.value).map((level) => ({ ...level, name: readableLevel(level.name) }))"
                                  :placeholder="filter.name"
                                  :searchable="false"
                                  :closeOnSelect="true"
                                  :value="levelFilter ? filteredLevels.find((filter) => filter.value === levelFilter.value) : null"
                                  track-by="value"
                                  label="name"
                                  :showLabels="false"
                                  :hideSelected="false"
                                  :multiple="false"
                                  @valueChange="$emit('levelChange', $event)">
                                    <div v-if="filter.label" slot="label" class="lg:text-base text-sm font-bold">{{ filter.label }}</div>
                                    <template slot="singleLabel" slot-scope="{ option }">
                                        {{ readableLevel(option.name) }}
                                    </template>
                                    <template slot="option" slot-scope="{ option }">
                                        <div class="flex items-center justify-between">
                                            {{ option.name }}
                                        </div>
                                    </template>
                                    <div slot="after"
                                      @click="$emit('levelReset')"
                                      :class="{ hidden: !levelFilter }"
                                      class="ml-4 cursor-pointer">
                                        <IconCross style="width: 12px;" />
                                    </div>
                                </BaseMultiSelect>
                            </div>
                        </template>
                        <template v-if="filter.type === 'subjects'">
                            <div :class="filter.classname" :key="filter.name">
                                <BaseMultiSelect :key="JSON.stringify(filteredSubjects)"
                                  :options="[...filteredSubjects].sort((a, b) => a.name.localeCompare(b.name))"
                                  :placeholder="filter.name"
                                  :searchable="false"
                                  :closeOnSelect="false"
                                  :value="subjectsFilter"
                                  track-by="name"
                                  label="name"
                                  :showLabels="false"
                                  :hideSelected="false"
                                  :multiple="true"
                                  @valueChange="$emit('subjectsChange', $event)">
                                    <div v-if="filter.label" slot="label" class="lg:text-base text-sm font-bold">{{ filter.label }}</div>
                                    <template slot="selection" slot-scope="{ values }">
                                        <span class="multiselect__single" v-if="values.length === 1">
                                            {{ values[0].name }}
                                        </span>
                                        <span class="multiselect__single" v-else-if="values.length > 1">
                                            {{ values.length }}
                                            {{ $filters.plural('matière', values.length) }}
                                            {{ $filters.plural('sélectionnée', values.length) }}
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="{ option }">
                                        <div class="flex items-center justify-between">
                                            {{ option.name }}
                                            <BaseCheckbox class="no-label"
                                              :checked="!!subjectsFilter.find((filter) => filter.name === option.name)">
                                            </BaseCheckbox>
                                        </div>
                                    </template>
                                    <div slot="after"
                                      @click="$emit('subjectsReset')"
                                      :class="{ hidden: !subjectsFilter || !subjectsFilter.length }"
                                      class="ml-4 cursor-pointer">
                                        <IconCross style="width: 12px;" />
                                    </div>
                                </BaseMultiSelect>
                            </div>
                        </template>
                        <template v-if="filter.type === 'period'">
                            <div :class="filter.classname" :key="filter.name">
                                <BaseMultiSelect :key="JSON.stringify(allPeriods)"
                                  :options="allPeriods"
                                  :placeholder="filter.name"
                                  :value="periodFilter ? allPeriods.find((filter) => filter.value === periodFilter.value) : null"
                                  :searchable="false"
                                  :closeOnSelect="true"
                                  track-by="value"
                                  label="name"
                                  :showLabels="false"
                                  :hideSelected="false"
                                  :multiple="false"
                                  @valueChange="$emit('periodsChange', $event)"
                                >
                                    <div v-if="filter.label" slot="label" class="lg:text-base text-sm font-bold">{{ filter.label }}</div>
                                    <template slot="singleLabel" slot-scope="{ option }">
                                        {{ option.name }}
                                    </template>
                                    <template slot="option" slot-scope="{ option }">
                                        <div class="flex items-center justify-between">
                                            {{ option.name }}
                                        </div>
                                    </template>
                                    <div slot="after"
                                      @click="$emit('periodsReset')"
                                      :class="{ hidden: !periodFilter }"
                                      class="ml-4 cursor-pointer">
                                        <IconCross style="width: 12px;" />
                                    </div>
                                </BaseMultiSelect>
                            </div>
                        </template>
                        <template v-if="filter.type === 'planning'">
                            <div :class="filter.classname" :key="filter.name">
                                <BaseMultiSelect :key="JSON.stringify(filter.values)"
                                  :options="Object.keys(filter.values)"
                                  :placeholder="filter.name"
                                  :searchable="false"
                                  :closeOnSelect="true"
                                  :value="planningFilter"
                                  :showLabels="false"
                                  :hideSelected="false"
                                  :multiple="false"
                                  @valueChange="$emit('planningChange', $event)">
                                    <div v-if="filter.label" slot="label" class="lg:text-base text-sm font-bold">{{ filter.label }}</div>
                                    <template slot="singleLabel" slot-scope="{ option }">
                                        {{ filter.values[option] }}
                                    </template>
                                    <template slot="option" slot-scope="{ option }">
                                        <div class="flex items-center justify-between">
                                            {{ filter.values[option] }}
                                        </div>
                                    </template>
                                </BaseMultiSelect>
                            </div>
                        </template>
                    </template>
                    <div class="lg:shrink-0 lg:ml-5 mt-4 lg:mt-0 flex self-end justify-center lg:block">
                        <BaseButton style="height: 50px"
                          class="base-button-solid text-sm font-semibold"
                          @click="$emit('findCourses')">
                            <span>Trouver mon <template v-if="isStage">stage</template><template v-else>cours</template></span>
                        </BaseButton>
                    </div>
                </div>
            </div>
        </div>

        <div class="">
            <div class="w-full lg:flex justify-center hidden" v-if="!displayCoursesList && landing && landing.manuscrit" >
                <img style="width:37px; height: 45px; transform: scaleY(-1); position: relative; top: -15px" src="../assets/arrow-manuscrit.svg"/>
                <div class="block-type-wysiwyg block-manuscrit" v-html="renderMustache(landing.manuscrit, { model: model, count: matchingCoursesLength })"></div>
            </div>

            <div class="text-center font-bold lg:text-2xl" v-else-if="matchingCoursesLength > 0 && !coursesLoading">
                Nous avons {{ matchingCoursesLength }}
                <template v-if="isStage">
                    <span class="stabilo">{{ $filters.plural('stage', matchingCoursesLength) }}</span>
                </template>
                <template v-else>
                    <span class="stabilo">cours</span>
                </template>
                <template v-if="subjectsFilter && subjectsFilter.length">
                    en <span class="stabilo">{{ subjectsFilter.map(({ name }) => name).join(', ') }}</span>
                </template>
                <template v-if="levelFilter">
                    niveau <span class="stabilo">{{levelFilter.name}}</span>
                </template>

                <template v-if="periodFilter">
                    <span v-html="readablePeriodFilter(periodFilter)"></span>
                </template>
            </div>
        </div>
        <template v-if="displayCoursesList">
            <div v-if="coursesLoading" class="pb-5">
                <div class="flex justify-center items-center py-5">
                    <BaseLoader class="w-16"/>
                </div>
            </div>
            <div v-else-if="!matchingCoursesLength" class="pb-5 ">
                <div class="leading-7" style="max-width: 650px;" v-html="renderMustache(landing.error_message, { model: model })">
                </div>
            </div>
            <div v-else ref="scrollWrapper" class="relative pb-5">
                <div
                  class="z-[3] hidden gradient-left w-14 cursor-pointer lg:inline-flex absolute top-0 bottom-10 left-0"
                  :style="{opacity: scrollLeft ? 1 : 0}"
                  @click="handlePrevClick">
                    <IconArrowheadLeft  style="width: 60px" />
                </div>
                <div
                  class="z-[3] hidden gradient-right w-14 cursor-pointer lg:inline-flex absolute top-0 bottom-10 right-0 flex-row-reverse"
                  @click="handleNextClick">
                    <IconArrowheadRight style="width: 60px" />
                </div>
                <div class="flex pb-1 justify-center space-x-8 lg:hidden pt-5 " >
                    <div
                      class="cursor-pointer inline-flex rounded-full border-solid border-black border text-black justify-center items-center"
                      :style="{ width: '45px', height: '45px'}"
                      @click="handlePrevClick">
                        <IconArrowheadLeft style="width: 16px; position:relative; right: 2px;" />
                    </div>
                    <div class="flex justify-between" >
                        <div style="width: 24px;" class="flex justify-center items-center">
                            <div class="rounded-full"
                              :class="`${activeScrollIndicator === 0 ? 'bg-black': 'bg-neutral-400'}`"
                              :style="{ width: '10px', height: '10px'}"></div>
                        </div>
                        <div style="width: 24px;" class="flex justify-center items-center">
                            <div class="rounded-full"
                              :class="`${activeScrollIndicator === 1 ? 'bg-black': 'bg-neutral-400'}`"
                              :style="{ width: '10px', height: '10px'}"></div>
                        </div>
                        <div style="width: 24px;" class="flex justify-center items-center">
                            <div class="rounded-full"
                              :class="`${activeScrollIndicator === 2 ? 'bg-black': 'bg-neutral-400'}`"
                              :style="{ width: '10px', height: '10px'}"></div>
                        </div>
                        <div style="width: 24px;" class="flex justify-center items-center">
                            <div class="rounded-full"
                              :class="`${activeScrollIndicator === 3 ? 'bg-black': 'bg-neutral-400'}`"
                              :style="{ width: '10px', height: '10px'}"></div>
                        </div>
                    </div>
                    <div
                      class="cursor-pointer inline-flex rounded-full border-solid border-black border text-black justify-center items-center"
                      :style="{ width: '45px', height: '45px'}"
                      @click="handleNextClick">
                        <IconArrowheadRight style="width: 16px; position:relative; left: 2px;" />
                    </div>
                </div>
                <div id="observer-root" @scroll="debouncedHandleScroll" class="pb-5 pt-5 overflow-auto">
                    <div class="flex space-x-6 desktop-pseudo-paddding">
                        <CourseCard v-for="group in matchingCourses"
                          :group="group"
                          :key="group[0].id"
                          :checkedVariantId="checkedVariantsIds[group[0].id]"
                          :class="{
                            active: selectedCourses.find((selectedCourse) => selectedCourse.group[0].id === group[0].id),
                            'selected-label': selectedCourses.find((selectedCourse) => selectedCourse.group[0].id === group[0].id),
                          }"
                          class="one-course w-3/4 lg:w-[550px]"
                          :isStage="isStage"
                          :detail="getDetailById(group[0].produitId)"
                          @courseProgramClick="(group, course) => $emit('courseProgramClick', group, course)"
                          @bookCourse="(group, course) => $emit('bookCourse', group, course)"
                          @variantChange="handleVariantChange">
                            <template #bottom="{ checkedVariantId, course, detail }">
                                <div class="lg:flex lg:items-center lg:flex-wrap lg:justify-between p-4 lg:py-5 lg:px-6">
                                    <div class="grow mb-4 lg:mb-0">
                                        <template v-if="isStage">
                                            <span class="text-sm">Tarif</span>
                                            <div class="font-bold text-2xl">
                                                {{ course.tarif }} €
                                            </div>
                                        </template>
                                        <template v-else-if="course.produitDesc.includes('Aide aux devoirs') && !course.enLigne && ['CP', 'CE1', 'CE2', 'CM1', 'CM2'].includes(course.niveauLib)">
                                            <span class="text-sm">Abonnement sans engagement</span>
                                            <div class="font-bold text-2xl">
                                                {{ course.tarif }} € / mois
                                            </div>
                                        </template>
                                        <template v-else>
                                            <span class="text-sm">Abonnement sans engagement</span>
                                            <div class="font-bold text-2xl">
                                                {{ course.tarif }} € / mois
                                            </div>
                                        </template>
                                    </div>
                                    <div v-if="detail" class="lg:mr-3 mb-4 lg:mb-0">
                                        <BaseButton
                                          @click.native="$emit('courseProgramClick', group, course)"
                                          class="base-button-color font-semibold px-8">
                                            <span>Voir le détail</span>
                                        </BaseButton>
                                    </div>
                                    <div>
                                        <BaseButton v-if="selectedCourses.find((selectedCourse) => selectedCourse.group[0].id === group[0].id)"
                                          @click.native="handleRemoveCourseClick(group)"
                                          class="base-button-outline font-semibold px-8">
                                            <span>Déselectionner</span>
                                        </BaseButton>
                                        <BaseButton v-else @click.native="handleSelectCourseClick(group, checkedVariantId)"
                                          class="base-button-solid px-8 font-semibold">
                                            <span>Choisir ce <template v-if="isStage">stage</template><template v-else>cours</template></span>
                                        </BaseButton>
                                    </div>
                                </div>
                            </template>
                        </CourseCard>
                    </div>
                </div>

            </div>
        </template>
    </div>
</template>

<style lang="stylus">
.home-screen
    #observer-root
        scroll-snap-type x mandatory
        scrollbar-width thin
        scrollbar-color rgba(0, 0, 0, .6) rgba(0, 0, 0, 0.3)

        &:hover
            scrollbar-color: rgba(0, 0, 0, .6) rgba(0, 0, 0, 0.3)

        &::-webkit-scrollbar
            width 6px
            height 6px

        &::-webkit-scrollbar-track
            transition background ease .2s
            background rgba(0, 0, 0, 0.3)

        &::-webkit-scrollbar-thumb
            transition background-color ease .2s
            border-radius 6px
            background-color rgba(0, 0, 0, 0.6)

        &:hover::-webkit-scrollbar-thumb
            background-color rgba(0, 0, 0, 0.6)

        .one-course
            scroll-snap-align center
            box-shadow 0px 0px 3px 3px rgba(0, 0, 0, 0.07)

    @media (min-width: 1024px)
        .desktop-pseudo-paddding:before
        .desktop-pseudo-paddding:after
            content ''
            display block
            width 60px
            flex-shrink 0

</style>